/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFDocumentUpload' store object.
 * @namespace NFDocumentUploadSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFDocumentUpload';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static fetchNfDocumentUploadOutstandingVerifications = state =>
    state[selectors.moduleIdentifier].fetchNfDocumentUploadOutstandingVerifications.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingFetchNfDocumentUploadOutstandingVerifications = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfDocumentUploadOutstandingVerifications
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static fetchNfDocumentUploadOutstandingVerificationsError = state =>
    state[selectors.moduleIdentifier].fetchNfDocumentUploadOutstandingVerificationsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static fetchNfDocumentUploadRejectedDocuments = state =>
    state[selectors.moduleIdentifier].fetchNfDocumentUploadRejectedDocuments.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingFetchNfDocumentUploadRejectedDocuments = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfDocumentUploadRejectedDocuments.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static fetchNfDocumentUploadRejectedDocumentsError = state =>
    state[selectors.moduleIdentifier].fetchNfDocumentUploadRejectedDocumentsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkId = state =>
    state[selectors.moduleIdentifier].getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkId
      .payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingGetNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkId = state =>
    state[selectors.moduleIdentifier]
      .isFetchingGetNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkId.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdError = state =>
    state[selectors.moduleIdentifier]
      .getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadDeleteUploadedVerificationDocuments = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadDeleteUploadedVerificationDocuments
      .payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadDeleteUploadedVerificationDocuments = state =>
    state[selectors.moduleIdentifier]
      .isFetchingPostNfDocumentUploadDeleteUploadedVerificationDocuments.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadDeleteUploadedVerificationDocumentsError = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadDeleteUploadedVerificationDocumentsError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadVerificationDocument = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadVerificationDocument.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadUploadVerificationDocument = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfDocumentUploadUploadVerificationDocument
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadVerificationDocumentError = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadVerificationDocumentError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadSubmitVerificationDocuments = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadSubmitVerificationDocuments.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadSubmitVerificationDocuments = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfDocumentUploadSubmitVerificationDocuments
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadSubmitVerificationDocumentsError = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadSubmitVerificationDocumentsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadSubmitNonLinkedStandaloneDocuments = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadSubmitNonLinkedStandaloneDocuments
      .payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadSubmitNonLinkedStandaloneDocuments = state =>
    state[selectors.moduleIdentifier]
      .isFetchingPostNfDocumentUploadSubmitNonLinkedStandaloneDocuments.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadSubmitNonLinkedStandaloneDocumentsError = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadSubmitNonLinkedStandaloneDocumentsError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadMultipleVerificationDocuments = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadMultipleVerificationDocuments
      .payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadUploadMultipleVerificationDocuments = state =>
    state[selectors.moduleIdentifier]
      .isFetchingPostNfDocumentUploadUploadMultipleVerificationDocuments.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadMultipleVerificationDocumentsError = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadMultipleVerificationDocumentsError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static fetchNfDocUploadIsUserLinked = state =>
    state[selectors.moduleIdentifier].fetchNfDocUploadIsUserLinked.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingFetchNfDocUploadIsUserLinked = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfDocUploadIsUserLinked.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static fetchNfDocUploadIsUserLinkedError = state =>
    state[selectors.moduleIdentifier].fetchNfDocUploadIsUserLinkedError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadDsnapDocument = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadDsnapDocument.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadUploadDsnapDocument = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfDocumentUploadUploadDsnapDocument.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadDsnapDocumentError = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadDsnapDocumentError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadMultipleDsnapDocuments = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadMultipleDsnapDocuments.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadUploadMultipleDsnapDocuments = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfDocumentUploadUploadMultipleDsnapDocuments
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadMultipleDsnapDocumentsError = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadMultipleDsnapDocumentsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadOnlineDocument = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadOnlineDocument.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadUploadOnlineDocument = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfDocumentUploadUploadOnlineDocument.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadOnlineDocumentError = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadOnlineDocumentError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadMultipleOnlineDocuments = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadMultipleOnlineDocuments.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadUploadMultipleOnlineDocuments = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfDocumentUploadUploadMultipleOnlineDocuments
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadMultipleOnlineDocumentsError = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadMultipleOnlineDocumentsError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static fetchNfDocumentUploadUploadedStandaloneDocuments = state =>
    state[selectors.moduleIdentifier].fetchNfDocumentUploadUploadedStandaloneDocuments.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingFetchNfDocumentUploadUploadedStandaloneDocuments = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfDocumentUploadUploadedStandaloneDocuments
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static fetchNfDocumentUploadUploadedStandaloneDocumentsError = state =>
    state[selectors.moduleIdentifier].fetchNfDocumentUploadUploadedStandaloneDocumentsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadMultipleStandaloneDocuments = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadMultipleStandaloneDocuments.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadUploadMultipleStandaloneDocuments = state =>
    state[selectors.moduleIdentifier]
      .isFetchingPostNfDocumentUploadUploadMultipleStandaloneDocuments.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadMultipleStandaloneDocumentsError = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadMultipleStandaloneDocumentsError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocuments = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocuments
      .payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadUploadMultipleNonLinkedStandaloneDocuments = state =>
    state[selectors.moduleIdentifier]
      .isFetchingPostNfDocumentUploadUploadMultipleNonLinkedStandaloneDocuments.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsError = state =>
    state[selectors.moduleIdentifier]
      .postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadDeleteUploadedStandaloneDocuments = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadDeleteUploadedStandaloneDocuments.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadDeleteUploadedStandaloneDocuments = state =>
    state[selectors.moduleIdentifier]
      .isFetchingPostNfDocumentUploadDeleteUploadedStandaloneDocuments.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadDeleteUploadedStandaloneDocumentsError = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadDeleteUploadedStandaloneDocumentsError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadMultipleEnergyDocuments = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadMultipleEnergyDocuments.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingPostNfDocumentUploadUploadMultipleEnergyDocuments = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfDocumentUploadUploadMultipleEnergyDocuments
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static postNfDocumentUploadUploadMultipleEnergyDocumentsError = state =>
    state[selectors.moduleIdentifier].postNfDocumentUploadUploadMultipleEnergyDocumentsError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static fetchNfDocumentUploadUploadedEnergyDocuments = state =>
    state[selectors.moduleIdentifier].fetchNfDocumentUploadUploadedEnergyDocuments.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static isFetchingFetchNfDocumentUploadUploadedEnergyDocuments = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfDocumentUploadUploadedEnergyDocuments
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocumentUploadSelectors
   */
  static fetchNfDocumentUploadUploadedEnergyDocumentsError = state =>
    state[selectors.moduleIdentifier].fetchNfDocumentUploadUploadedEnergyDocumentsError.payload;
}
