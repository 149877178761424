/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the CaseEvidenceVerificationDetails
 *
 * @class CaseEvidenceVerificationDetails
 */
class CaseEvidenceVerificationDetails {
  /**
   * Creates an instance of CaseEvidenceVerificationDetails.
   * @param {*} json
   * @memberof CaseEvidenceVerificationDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.primaryClient = json.primaryClient;

      // string
      this.concernRoleID = json.concernRoleID;

      // string
      this.verifiableDataItemName = json.verifiableDataItemName;

      // string
      this.evidence = json.evidence;

      // string
      this.dueDate = json.dueDate;

      // object - CodeTableType
      if (json.verificationStatus) {
        this.verificationStatus = new CodeTableType(json.verificationStatus);
      }

      // string
      this.vDIEDLinkID = json.vDIEDLinkID;

      // string
      this.verificationID = json.verificationID;

      // string
      this.evidenceDescriptorID = json.evidenceDescriptorID;

      // string
      this.contextDescription = json.contextDescription;

      // boolean
      this.mandatory = json.mandatory;

      // string
      this.caseParticpantRoleID = json.caseParticpantRoleID;

      // boolean
      this.itemProvided = json.itemProvided;

      // object - CodeTableType
      if (json.evidenceTypeOpt) {
        this.evidenceTypeOpt = new CodeTableType(json.evidenceTypeOpt);
      }

      // string
      this.verifiableDataItemIDOpt = json.verifiableDataItemIDOpt;

      // boolean
      this.documentSubmittedOpt = json.documentSubmittedOpt;
    }
  }
}

/**
 * Models the NFDULVerificationAttachmentLinkVerificationItemIDKey
 *
 * @class NFDULVerificationAttachmentLinkVerificationItemIDKey
 */
class NFDULVerificationAttachmentLinkVerificationItemIDKey {
  /**
   * Creates an instance of NFDULVerificationAttachmentLinkVerificationItemIDKey.
   * @param {*} json
   * @memberof NFDULVerificationAttachmentLinkVerificationItemIDKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.verificationItemProvidedID = json.verificationItemProvidedID;
    }
  }
}

/**
 * Models the NFDULVerificationItemUtilIDDetails
 *
 * @class NFDULVerificationItemUtilIDDetails
 */
class NFDULVerificationItemUtilIDDetails {
  /**
   * Creates an instance of NFDULVerificationItemUtilIDDetails.
   * @param {*} json
   * @memberof NFDULVerificationItemUtilIDDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.verificationItemUtilizationID = json.verificationItemUtilizationID;
    }
  }
}

/**
 * Models the NFDULCaseEvidenceVerificationDetails
 *
 * @class NFDULCaseEvidenceVerificationDetails
 */
class NFDULCaseEvidenceVerificationDetails {
  /**
   * Creates an instance of NFDULCaseEvidenceVerificationDetails.
   * @param {*} json
   * @memberof NFDULCaseEvidenceVerificationDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.documentLink = json.documentLink;

      // string
      this.attachmentID = json.attachmentID;

      // object - CodeTableType
      if (json.documentStatus) {
        this.documentStatus = new CodeTableType(json.documentStatus);
      }

      // boolean
      this.showDocumentIconInd = json.showDocumentIconInd;

      // boolean
      this.showSubmitActionInd = json.showSubmitActionInd;

      // boolean
      this.showDeleteActionInd = json.showDeleteActionInd;

      // string
      this.sourceStrForSingleDoc = json.sourceStrForSingleDoc;

      // string
      this.caseID = json.caseID;

      // string
      this.participantName = json.participantName;

      // string
      this.itemDescription = json.itemDescription;

      // boolean
      this.showAddActionInd = json.showAddActionInd;

      // string
      this.programName = json.programName;

      // string
      this.attachmentName = json.attachmentName;

      // boolean
      this.showViewActionInd = json.showViewActionInd;

      // string
      this.latestActivity = json.latestActivity;

      // boolean
      this.showAcceptClearInd = json.showAcceptClearInd;

      // string
      this.documentStatusDescription = json.documentStatusDescription;

      // boolean
      this.showAcceptInd = json.showAcceptInd;

      // string
      this.attachmentDate = json.attachmentDate;

      // object - CodeTableType
      if (json.documentType) {
        this.documentType = new CodeTableType(json.documentType);
      }

      // boolean
      this.showViewRemoveActionInd = json.showViewRemoveActionInd;

      // boolean
      this.showRejectInd = json.showRejectInd;

      // boolean
      this.showAddInfotInd = json.showAddInfotInd;

      // boolean
      this.showAddTypeAndClearInd = json.showAddTypeAndClearInd;

      // boolean
      this.showAddTypeInd = json.showAddTypeInd;

      // object - CaseEvidenceVerificationDetails
      if (json.dtls) {
        this.dtls = new CaseEvidenceVerificationDetails(json.dtls);
      }

      // object - NFDULVerificationAttachmentLinkVerificationItemIDKey
      if (json.vipKey) {
        this.vipKey = new NFDULVerificationAttachmentLinkVerificationItemIDKey(json.vipKey);
      }

      // object - NFDULVerificationItemUtilIDDetails
      if (json.verDtls) {
        this.verDtls = new NFDULVerificationItemUtilIDDetails(json.verDtls);
      }
    }
  }
}

/**
 * Models the NFOutstandingVerificationsResponse
 *
 * @class NFOutstandingVerificationsResponse
 */
class NFOutstandingVerificationsResponse {
  /**
   * Creates an instance of NFOutstandingVerificationsResponse.
   * @param {*} json
   * @memberof NFOutstandingVerificationsResponse
   */
  constructor(json = null) {
    if (json) {
      // array - NFDULCaseEvidenceVerificationDetails
      if (json.outstandingVerificationList) {
        this.outstandingVerificationList = json.outstandingVerificationList.map(
          object => new NFDULCaseEvidenceVerificationDetails(object)
        );
      } else if (json.constructor === Array) {
        this.outstandingVerificationList = json.map(
          object => new NFDULCaseEvidenceVerificationDetails(object)
        );
      }
    }
  }
}

/**
 * Models the NFDULRejectedDocumentDetails
 *
 * @class NFDULRejectedDocumentDetails
 */
class NFDULRejectedDocumentDetails {
  /**
   * Creates an instance of NFDULRejectedDocumentDetails.
   * @param {*} json
   * @memberof NFDULRejectedDocumentDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.rejectionParticipantName = json.rejectionParticipantName;

      // string
      this.rejectionComments = json.rejectionComments;

      // object - CodeTableType
      if (json.documentRejectionReason) {
        this.documentRejectionReason = new CodeTableType(json.documentRejectionReason);
      }

      // string
      this.vDIEDLinkId = json.vDIEDLinkId;

      // boolean
      this.showAddActionInd = json.showAddActionInd;

      // string
      this.attachmentID = json.attachmentID;

      // string
      this.programName = json.programName;

      // string
      this.verifiableDataItemName = json.verifiableDataItemName;

      // object - CaseEvidenceVerificationDetails
      if (json.rejectDtls) {
        this.rejectDtls = new CaseEvidenceVerificationDetails(json.rejectDtls);
      }
    }
  }
}

/**
 * Models the NFRejectedVerificationDocumentsResponse
 *
 * @class NFRejectedVerificationDocumentsResponse
 */
class NFRejectedVerificationDocumentsResponse {
  /**
   * Creates an instance of NFRejectedVerificationDocumentsResponse.
   * @param {*} json
   * @memberof NFRejectedVerificationDocumentsResponse
   */
  constructor(json = null) {
    if (json) {
      // array - NFDULRejectedDocumentDetails
      if (json.rejectedDocumentList) {
        this.rejectedDocumentList = json.rejectedDocumentList.map(
          object => new NFDULRejectedDocumentDetails(object)
        );
      } else if (json.constructor === Array) {
        this.rejectedDocumentList = json.map(object => new NFDULRejectedDocumentDetails(object));
      }
    }
  }
}

/**
 * Models the NFUploadedVerificationDocumentsResponse
 *
 * @class NFUploadedVerificationDocumentsResponse
 */
class NFUploadedVerificationDocumentsResponse {
  /**
   * Creates an instance of NFUploadedVerificationDocumentsResponse.
   * @param {*} json
   * @memberof NFUploadedVerificationDocumentsResponse
   */
  constructor(json = null) {
    if (json) {
      // array - NFDULCaseEvidenceVerificationDetails
      if (json.uploadedDocumentList) {
        this.uploadedDocumentList = json.uploadedDocumentList.map(
          object => new NFDULCaseEvidenceVerificationDetails(object)
        );
      } else if (json.constructor === Array) {
        this.uploadedDocumentList = json.map(
          object => new NFDULCaseEvidenceVerificationDetails(object)
        );
      }
    }
  }
}

/**
 * Models the NewNFUploadedVerificationDocumentDetails
 *
 * @class NewNFUploadedVerificationDocumentDetails
 */
class NewNFUploadedVerificationDocumentDetails {
  /**
   * Creates an instance of NewNFUploadedVerificationDocumentDetails.
   * @param {*} json
   * @memberof NewNFUploadedVerificationDocumentDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.verification_item_provided_id = json.verification_item_provided_id;
    }
  }
}

/**
 * Models the NewNFDeleteUploadedVerificationDocumentsRequest
 *
 * @class NewNFDeleteUploadedVerificationDocumentsRequest
 */
class NewNFDeleteUploadedVerificationDocumentsRequest {
  /**
   * Creates an instance of NewNFDeleteUploadedVerificationDocumentsRequest.
   * @param {*} json
   * @memberof NewNFDeleteUploadedVerificationDocumentsRequest
   */
  constructor(json = null) {
    if (json) {
      // array - NewNFUploadedVerificationDocumentDetails
      if (json.uploadedDocumentList) {
        this.uploadedDocumentList = json.uploadedDocumentList.map(
          object => new NewNFUploadedVerificationDocumentDetails(object)
        );
      } else if (json.constructor === Array) {
        this.uploadedDocumentList = json.map(
          object => new NewNFUploadedVerificationDocumentDetails(object)
        );
      }
    }
  }
}

/**
 * Models the NewNFDocumentUploadResponseResponse
 *
 * @class NewNFDocumentUploadResponseResponse
 */
class NewNFDocumentUploadResponseResponse {
  /**
   * Creates an instance of NewNFDocumentUploadResponseResponse.
   * @param {*} json
   * @memberof NewNFDocumentUploadResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.message = json.message;

      // string
      this.vDIEDLinkID = json.vDIEDLinkID;
    }
  }
}

/**
 * Models the NewNFUploadVerificationDocumentRequest
 *
 * @class NewNFUploadVerificationDocumentRequest
 */
class NewNFUploadVerificationDocumentRequest {
  /**
   * Creates an instance of NewNFUploadVerificationDocumentRequest.
   * @param {*} json
   * @memberof NewNFUploadVerificationDocumentRequest
   */
  constructor(json = null) {
    if (json) {
      // string
      this.attachment_name = json.attachment_name;

      // string
      this.verification_item_utilization_id = json.verification_item_utilization_id;

      // string
      this.attachment_id = json.attachment_id;

      // string
      this.attachment_base_64_data = json.attachment_base_64_data;

      // string
      this.vdied_link_id = json.vdied_link_id;
    }
  }
}

/**
 * Models the NewNFUploadVerificationDocumentResponseResponse
 *
 * @class NewNFUploadVerificationDocumentResponseResponse
 */
class NewNFUploadVerificationDocumentResponseResponse {
  /**
   * Creates an instance of NewNFUploadVerificationDocumentResponseResponse.
   * @param {*} json
   * @memberof NewNFUploadVerificationDocumentResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.attachment_name = json.attachment_name;

      // string
      this.message = json.message;

      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.verificationItemProvidedID = json.verificationItemProvidedID;
    }
  }
}

/**
 * Models the NewNFUploadedMultipleVerificationDocumentsRequest
 *
 * @class NewNFUploadedMultipleVerificationDocumentsRequest
 */
class NewNFUploadedMultipleVerificationDocumentsRequest {
  /**
   * Creates an instance of NewNFUploadedMultipleVerificationDocumentsRequest.
   * @param {*} json
   * @memberof NewNFUploadedMultipleVerificationDocumentsRequest
   */
  constructor(json = null) {
    if (json) {
      // array - NewNFUploadVerificationDocumentRequest
      if (json.dtls) {
        this.dtls = json.dtls.map(object => new NewNFUploadVerificationDocumentRequest(object));
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new NewNFUploadVerificationDocumentRequest(object));
      }
    }
  }
}

/**
 * Models the NewNFUploadedMultipleVerificationDocumentsResponseResponse
 *
 * @class NewNFUploadedMultipleVerificationDocumentsResponseResponse
 */
class NewNFUploadedMultipleVerificationDocumentsResponseResponse {
  /**
   * Creates an instance of NewNFUploadedMultipleVerificationDocumentsResponseResponse.
   * @param {*} json
   * @memberof NewNFUploadedMultipleVerificationDocumentsResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // array - NewNFUploadVerificationDocumentResponseResponse
      if (json.dtls) {
        this.dtls = json.dtls.map(
          object => new NewNFUploadVerificationDocumentResponseResponse(object)
        );
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new NewNFUploadVerificationDocumentResponseResponse(object));
      }
    }
  }
}

/**
 * Models the NFLinkedInd
 *
 * @class NFLinkedInd
 */
class NFLinkedInd {
  /**
   * Creates an instance of NFLinkedInd.
   * @param {*} json
   * @memberof NFLinkedInd
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.nfLinkedInd = json.nfLinkedInd;

      // string
      this.nfUserFullName = json.nfUserFullName;
    }
  }
}

/**
 * Models the NewNFUploadDocumentRequest
 *
 * @class NewNFUploadDocumentRequest
 */
class NewNFUploadDocumentRequest {
  /**
   * Creates an instance of NewNFUploadDocumentRequest.
   * @param {*} json
   * @memberof NewNFUploadDocumentRequest
   */
  constructor(json = null) {
    if (json) {
      // string
      this.attachment_name = json.attachment_name;

      // string
      this.attachment_id = json.attachment_id;

      // string
      this.attachment_base_64_data = json.attachment_base_64_data;

      // string
      this.applicationID = json.applicationID;

      // string
      this.concernRoleID = json.concernRoleID;
    }
  }
}

/**
 * Models the NewNFUploadDocumentResponseResponse
 *
 * @class NewNFUploadDocumentResponseResponse
 */
class NewNFUploadDocumentResponseResponse {
  /**
   * Creates an instance of NewNFUploadDocumentResponseResponse.
   * @param {*} json
   * @memberof NewNFUploadDocumentResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.attachment_name = json.attachment_name;

      // string
      this.message = json.message;

      // boolean
      this.isSuccess = json.isSuccess;
    }
  }
}

/**
 * Models the NewNFUploadMultipleDocumentsRequest
 *
 * @class NewNFUploadMultipleDocumentsRequest
 */
class NewNFUploadMultipleDocumentsRequest {
  /**
   * Creates an instance of NewNFUploadMultipleDocumentsRequest.
   * @param {*} json
   * @memberof NewNFUploadMultipleDocumentsRequest
   */
  constructor(json = null) {
    if (json) {
      // array - NewNFUploadDocumentRequest
      if (json.dtls) {
        this.dtls = json.dtls.map(object => new NewNFUploadDocumentRequest(object));
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new NewNFUploadDocumentRequest(object));
      }
    }
  }
}

/**
 * Models the NewNFUploadMultipleDocumentsResponseResponse
 *
 * @class NewNFUploadMultipleDocumentsResponseResponse
 */
class NewNFUploadMultipleDocumentsResponseResponse {
  /**
   * Creates an instance of NewNFUploadMultipleDocumentsResponseResponse.
   * @param {*} json
   * @memberof NewNFUploadMultipleDocumentsResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // array - NewNFUploadDocumentResponseResponse
      if (json.dtls) {
        this.dtls = json.dtls.map(object => new NewNFUploadDocumentResponseResponse(object));
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new NewNFUploadDocumentResponseResponse(object));
      }
    }
  }
}

/**
 * Models the NFDULCaseEvidenceStandaloneDetails
 *
 * @class NFDULCaseEvidenceStandaloneDetails
 */
class NFDULCaseEvidenceStandaloneDetails {
  /**
   * Creates an instance of NFDULCaseEvidenceStandaloneDetails.
   * @param {*} json
   * @memberof NFDULCaseEvidenceStandaloneDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.documentLink = json.documentLink;

      // string
      this.attachmentID = json.attachmentID;

      // object - CodeTableType
      if (json.documentStatus) {
        this.documentStatus = new CodeTableType(json.documentStatus);
      }

      // boolean
      this.showDocumentIconInd = json.showDocumentIconInd;

      // boolean
      this.showSubmitActionInd = json.showSubmitActionInd;

      // boolean
      this.showDeleteActionInd = json.showDeleteActionInd;

      // string
      this.sourceStrForSingleDoc = json.sourceStrForSingleDoc;

      // string
      this.caseID = json.caseID;

      // string
      this.participantName = json.participantName;

      // string
      this.itemDescription = json.itemDescription;

      // boolean
      this.showAddActionInd = json.showAddActionInd;

      // string
      this.programName = json.programName;

      // string
      this.attachmentName = json.attachmentName;

      // boolean
      this.showViewActionInd = json.showViewActionInd;

      // string
      this.latestActivity = json.latestActivity;

      // boolean
      this.showAcceptClearInd = json.showAcceptClearInd;

      // string
      this.documentStatusDescription = json.documentStatusDescription;

      // boolean
      this.showAcceptInd = json.showAcceptInd;

      // string
      this.attachmentDate = json.attachmentDate;

      // object - CodeTableType
      if (json.documentType) {
        this.documentType = new CodeTableType(json.documentType);
      }

      // string
      this.nfDULStandaloneAttachmentID = json.nfDULStandaloneAttachmentID;
    }
  }
}

/**
 * Models the NFUploadedStandaloneDocumentsResponse
 *
 * @class NFUploadedStandaloneDocumentsResponse
 */
class NFUploadedStandaloneDocumentsResponse {
  /**
   * Creates an instance of NFUploadedStandaloneDocumentsResponse.
   * @param {*} json
   * @memberof NFUploadedStandaloneDocumentsResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.concernrole_id = json.concernrole_id;

      // boolean
      this.showReadyToSubmitBadgeInd = json.showReadyToSubmitBadgeInd;

      // array - NFDULCaseEvidenceStandaloneDetails
      if (json.uploadedStandaloneList) {
        this.uploadedStandaloneList = json.uploadedStandaloneList.map(
          object => new NFDULCaseEvidenceStandaloneDetails(object)
        );
      } else if (json.constructor === Array) {
        this.uploadedStandaloneList = json.map(
          object => new NFDULCaseEvidenceStandaloneDetails(object)
        );
      }
    }
  }
}

/**
 * Models the NewNFUploadedStandaloneDeleteDocumentDetails
 *
 * @class NewNFUploadedStandaloneDeleteDocumentDetails
 */
class NewNFUploadedStandaloneDeleteDocumentDetails {
  /**
   * Creates an instance of NewNFUploadedStandaloneDeleteDocumentDetails.
   * @param {*} json
   * @memberof NewNFUploadedStandaloneDeleteDocumentDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.nfDULStandaloneAttachmentID = json.nfDULStandaloneAttachmentID;
    }
  }
}

/**
 * Models the NewNFDeleteUploadedStandaloneDocumentsRequest
 *
 * @class NewNFDeleteUploadedStandaloneDocumentsRequest
 */
class NewNFDeleteUploadedStandaloneDocumentsRequest {
  /**
   * Creates an instance of NewNFDeleteUploadedStandaloneDocumentsRequest.
   * @param {*} json
   * @memberof NewNFDeleteUploadedStandaloneDocumentsRequest
   */
  constructor(json = null) {
    if (json) {
      // array - NewNFUploadedStandaloneDeleteDocumentDetails
      if (json.standaloneDocumentList) {
        this.standaloneDocumentList = json.standaloneDocumentList.map(
          object => new NewNFUploadedStandaloneDeleteDocumentDetails(object)
        );
      } else if (json.constructor === Array) {
        this.standaloneDocumentList = json.map(
          object => new NewNFUploadedStandaloneDeleteDocumentDetails(object)
        );
      }
    }
  }
}

/**
 * Models the NewNFDocumentUploadStandaloneResponseResponse
 *
 * @class NewNFDocumentUploadStandaloneResponseResponse
 */
class NewNFDocumentUploadStandaloneResponseResponse {
  /**
   * Creates an instance of NewNFDocumentUploadStandaloneResponseResponse.
   * @param {*} json
   * @memberof NewNFDocumentUploadStandaloneResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.message = json.message;

      // string
      this.concernrole_id = json.concernrole_id;
    }
  }
}

/**
 * Models the NewNFApplicationID
 *
 * @class NewNFApplicationID
 */
class NewNFApplicationID {
  /**
   * Creates an instance of NewNFApplicationID.
   * @param {*} json
   * @memberof NewNFApplicationID
   */
  constructor(json = null) {
    if (json) {
      // string
      this.applicationID = json.applicationID;
    }
  }
}

/**
 * Models the NewNFENUploadDocumentRequest
 *
 * @class NewNFENUploadDocumentRequest
 */
class NewNFENUploadDocumentRequest {
  /**
   * Creates an instance of NewNFENUploadDocumentRequest.
   * @param {*} json
   * @memberof NewNFENUploadDocumentRequest
   */
  constructor(json = null) {
    if (json) {
      // string
      this.attachment_name = json.attachment_name;

      // string
      this.attachment_id = json.attachment_id;

      // string
      this.attachment_base_64_data = json.attachment_base_64_data;

      // string
      this.concernRoleID = json.concernRoleID;

      // array - NewNFApplicationID
      if (json.nfApplicationIDList) {
        this.nfApplicationIDList = json.nfApplicationIDList.map(
          object => new NewNFApplicationID(object)
        );
      } else if (json.constructor === Array) {
        this.nfApplicationIDList = json.map(object => new NewNFApplicationID(object));
      }
    }
  }
}

/**
 * Models the NewNFENUploadMultipleDocumentsRequest
 *
 * @class NewNFENUploadMultipleDocumentsRequest
 */
class NewNFENUploadMultipleDocumentsRequest {
  /**
   * Creates an instance of NewNFENUploadMultipleDocumentsRequest.
   * @param {*} json
   * @memberof NewNFENUploadMultipleDocumentsRequest
   */
  constructor(json = null) {
    if (json) {
      // array - NewNFENUploadDocumentRequest
      if (json.dtls) {
        this.dtls = json.dtls.map(object => new NewNFENUploadDocumentRequest(object));
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new NewNFENUploadDocumentRequest(object));
      }
    }
  }
}

/**
 * Models the NFUploadDocumentResponse
 *
 * @class NFUploadDocumentResponse
 */
class NFUploadDocumentResponse {
  /**
   * Creates an instance of NFUploadDocumentResponse.
   * @param {*} json
   * @memberof NFUploadDocumentResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.attachment_name = json.attachment_name;

      // string
      this.message = json.message;

      // boolean
      this.isSuccess = json.isSuccess;
    }
  }
}

/**
 * Models the NFUploadedDocumentsForApplication
 *
 * @class NFUploadedDocumentsForApplication
 */
class NFUploadedDocumentsForApplication {
  /**
   * Creates an instance of NFUploadedDocumentsForApplication.
   * @param {*} json
   * @memberof NFUploadedDocumentsForApplication
   */
  constructor(json = null) {
    if (json) {
      // string
      this.applicationRefs = json.applicationRefs;

      // array - NFUploadDocumentResponse
      if (json.uploadedDocumentList) {
        this.uploadedDocumentList = json.uploadedDocumentList.map(
          object => new NFUploadDocumentResponse(object)
        );
      } else if (json.constructor === Array) {
        this.uploadedDocumentList = json.map(object => new NFUploadDocumentResponse(object));
      }
    }
  }
}

/**
 * Models the NFENMultipleUploadedDocumentsResponse
 *
 * @class NFENMultipleUploadedDocumentsResponse
 */
class NFENMultipleUploadedDocumentsResponse {
  /**
   * Creates an instance of NFENMultipleUploadedDocumentsResponse.
   * @param {*} json
   * @memberof NFENMultipleUploadedDocumentsResponse
   */
  constructor(json = null) {
    if (json) {
      // array - NFUploadedDocumentsForApplication
      if (json.uploadedDocumentListForApp) {
        this.uploadedDocumentListForApp = json.uploadedDocumentListForApp.map(
          object => new NFUploadedDocumentsForApplication(object)
        );
      } else if (json.constructor === Array) {
        this.uploadedDocumentListForApp = json.map(
          object => new NFUploadedDocumentsForApplication(object)
        );
      }
    }
  }
}

export default {
  CodeTableType,

  CaseEvidenceVerificationDetails,

  NFDULVerificationAttachmentLinkVerificationItemIDKey,

  NFDULVerificationItemUtilIDDetails,

  NFDULCaseEvidenceVerificationDetails,

  NFOutstandingVerificationsResponse,

  NFDULRejectedDocumentDetails,

  NFRejectedVerificationDocumentsResponse,

  NFUploadedVerificationDocumentsResponse,

  NewNFUploadedVerificationDocumentDetails,

  NewNFDeleteUploadedVerificationDocumentsRequest,

  NewNFDocumentUploadResponseResponse,

  NewNFUploadVerificationDocumentRequest,

  NewNFUploadVerificationDocumentResponseResponse,

  NewNFUploadedMultipleVerificationDocumentsRequest,

  NewNFUploadedMultipleVerificationDocumentsResponseResponse,

  NFLinkedInd,

  NewNFUploadDocumentRequest,

  NewNFUploadDocumentResponseResponse,

  NewNFUploadMultipleDocumentsRequest,

  NewNFUploadMultipleDocumentsResponseResponse,

  NFDULCaseEvidenceStandaloneDetails,

  NFUploadedStandaloneDocumentsResponse,

  NewNFUploadedStandaloneDeleteDocumentDetails,

  NewNFDeleteUploadedStandaloneDocumentsRequest,

  NewNFDocumentUploadStandaloneResponseResponse,

  NewNFApplicationID,

  NewNFENUploadDocumentRequest,

  NewNFENUploadMultipleDocumentsRequest,

  NFUploadDocumentResponse,

  NFUploadedDocumentsForApplication,

  NFENMultipleUploadedDocumentsResponse,
};
