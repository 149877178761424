import { defineMessages } from 'react-intl';

export default defineMessages({
  NFApplicationHeader_bannerAlertCampaigns_Message: {
    id: 'NFApplicationHeader_bannerAlertCampaigns_Message',
    defaultMessage:
      //"Current FNS or SNAP recipients - If you have suffered a food loss due to a power outage or flooding from Tropical Storm Fred, select 'Continue'.",
      "If you have had benefits stolen due to card skimming, select 'Continue'.",
  },
  NFApplicationHeader_continueButton: {
    id: 'NFApplicationHeader_continueButton',
    defaultMessage: 'Continue',
  },
  NFApplicationHeader_headerMenu_Label: {
    id: 'NFApplicationHeader_headerMenu_Label',
    defaultMessage: 'Menu',
  },
  NFApplicationHeader_ePass_Title: {
    id: 'NFApplicationHeader_ePass_Title',
    defaultMessage: 'North Carolina ePASS',
  },
  NFApplicationHeader_headerHome_Label: {
    id: 'NFApplicationHeader_headerHome_Label',
    defaultMessage: 'Home',
  },
  NFApplicationHeader_headerDashboard_Label: {
    id: 'NFApplicationHeader_headerDashboard_Label',
    defaultMessage: 'Dashboard',
  },
  NFApplicationHeader_headerYourApplication_Label: {
    id: 'NFApplicationHeader_headerYourApplication_Label',
    defaultMessage: 'Your applications',
  },
  NFApplicationHeader_headerYourReferrals_Label: {
    id: 'NFApplicationHeader_headerYourReferrals_Label',
    defaultMessage: 'Your referrals',
  },
  NFApplicationHeader_headerArchivedReferrals_Label: {
    id: 'NFApplicationHeader_headerArchivedReferrals_Label',
    defaultMessage: 'Archived referrals',
  },
  NFApplicationHeader_headerAccountSetting_Label: {
    id: 'NFApplicationHeader_headerAccountSetting_Label',
    defaultMessage: 'Account Settings',
  },
  NFApplicationHeader_headerMyInformation_Label: {
    id: 'NFApplicationHeader_headerMyInformation_Label',
    defaultMessage: 'Your information',
  },
  NFApplicationHeader_headerApply_Label: {
    id: 'NFApplicationHeader_headerApply_Label',
    defaultMessage: 'Apply',
  },
  NFApplicationHeader_headerYourAccount_Label: {
    id: 'NFApplicationHeader_headerYourAccount_Label',
    defaultMessage: 'Your account',
  },
  NFApplicationHeader_headerLogOut_Label: {
    id: 'NFApplicationHeader_headerLogOut_Label',
    defaultMessage: 'Log out',
  },
  NFApplicationHeader_headerLogIn_Label: {
    id: 'NFApplicationHeader_headerLogIn_Label',
    defaultMessage: 'Log in',
  },
  NFApplicationHeader_headerSignUp_Label: {
    id: 'NFApplicationHeader_headerSignUp_Label',
    defaultMessage: 'Sign up',
  },
  NFApplicationHeader_continueButtonURL: {
    id: 'NFApplicationHeader_continueButtonURL',
    defaultMessage: 'https://policies.ncdhhs.gov/divisional/social-services/forms/dss-8644-affidavit-for-stolen-benefits/@@display-file/form_file/DSS-8644.pdf',
  },
});
